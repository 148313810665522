<template>
  <v-form ref="form" v-model="valid">
    <v-row md-dense>
      <v-col cols="12" md="4">
        <v-select class="pt-6" :items="titles" ref="anrede" v-model="newWorkerFormData.gender" :rules="requiredRule" label="Anrede *" outlined></v-select>
      </v-col>
      <v-col>
        <v-text-field class="pt-md-6 mt-n7 mt-md-0" v-model="newWorkerFormData.fullName" label="Vor- und Nachname *" :rules="requiredRule" required outlined></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-md-n6" md-dense>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="newWorkerFormData.birthdayDay"
          class="mt-n7 mt-md-0"
          :rules="requiredRule.concat([(v) => /^(0?[1-9]|[1-2][0-9]|3[0-1])$/.test(v) || 'Bitte den Tag angeben.'])"
          label="Geburtstag *"
          required
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="newWorkerFormData.birthdayMonth"
          class="mt-n7 mt-md-0"
          :rules="requiredRule.concat([(v) => /^(0?[1-9]|1[012])$/.test(v) || 'Bitte den Monat in MM angeben.'])"
          label="Geburtsmonat *"
          required
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="newWorkerFormData.birthdayYear"
          class="mt-n7 mt-md-0"
          :rules="requiredRule.concat([(v) => /^[0-9]{4}$/.test(v) || 'Bitte das Jahr in JJJJ angeben.'])"
          required
          label="Geburtsjahr *"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-md-n6" md-dense>
      <v-col><v-text-field v-model="newWorkerFormData.identityNumber" class="mt-n7 mt-md-0" label="Ausweisnummer" outlined></v-text-field> </v-col>
    </v-row>
    <v-row class="mt-md-n6" md-dense>
      <v-col
        ><v-text-field
          v-model="newWorkerFormData.emailAddress"
          class="mt-n7 mt-md-0"
          label="E-Mail Adresse *"
          :rules="requiredRule.concat([(v) => /.+@.+\..+/.test(v) || 'Bitte geben Sie eine gültige E-Mail Adresse an.'])"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-md-n6" md-dense>
      <v-col cols="12" md="8">
        <v-text-field v-model="newWorkerFormData.streetName" class="mt-n7 mt-md-0" :rules="requiredRule" required label="Straße *" outlined> </v-text-field>
      </v-col>
      <v-col>
        <v-text-field v-model="newWorkerFormData.houseNumber" class="mt-n7 mt-md-0" :rules="requiredRule" required label="Hausnummer *" outlined> </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-md-n6" md-dense>
      <v-col cols="12" md="4">
        <v-text-field v-model="newWorkerFormData.zipCode" class="mt-n7 mt-md-0" :rules="[() => !!newWorkerFormData.zipCode || 'Bitte nur Ziffern angeben.']" required label="Postleitzahl *" outlined> </v-text-field>
      </v-col>
      <v-col>
        <v-text-field v-model="newWorkerFormData.cityName" class="mt-n7 mt-md-0" :rules="requiredRule" required label="Stadt *" outlined> </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-md-n6" md-dense>
      <v-col
        ><v-text-field
          v-model="newWorkerFormData.phoneNumber"
          class="mt-n7 mt-md-0"
          label="Telefonnummer"
          :rules="[
            (v) => {
              if (v) return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(v) || 'Bitte geben Sie eine gültige Telefonnummer an.';
              else return true;
            },
          ]"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
const dayjs = require("dayjs");

export default {
  props: {
    user: Object,
  },
  data() {
    return {
      valid: false,
      requiredRule: [(v) => !!v || "Bitte füllen Sie das Feld aus."],
      titles: [
        { text: "Herr", value: "m" },
        { text: "Frau", value: "f" },
        { text: "Divers", value: "d" },
      ],
      newWorkerForm: false,
      newWorkerFormData: {
        fullName: "",
        emailAddress: "",
        gender: "",
        identityNumber: "",
        streetName: "",
        cityName: "",
        houseNumber: "",
        zipCode: 0,
        phoneNumber: "",
        birthdayDay: 0,
        birthdayMonth: 0,
        birthdayYear: 0,
      },
      workerLoading: false,
    };
  },
  mounted() {
    if (this.user.fullName) {
      let birthday = dayjs(this.user.birthday);
      this.newWorkerFormData.fullName = this.user.fullName;
      this.newWorkerFormData.emailAddress = this.user.emailAddress;
      this.newWorkerFormData.gender = this.user.gender;
      this.newWorkerFormData.identityNumber = this.user.identityNumber;
      this.newWorkerFormData.streetName = this.user.streetName;
      this.newWorkerFormData.cityName = this.user.cityName;
      this.newWorkerFormData.houseNumber = this.user.houseNumber;
      this.newWorkerFormData.zipCode = this.user.zipCode;
      this.newWorkerFormData.phoneNumber = this.user.phoneNumber;
      this.newWorkerFormData.birthdayDay = birthday.date();
      this.newWorkerFormData.birthdayMonth = birthday.month() + 1;
      this.newWorkerFormData.birthdayYear = birthday.year();
    }
    this.$emit("update:formBaseData", this.newWorkerFormData);
  },
  watch: {
    user: function(user) {
      let birthday = dayjs(user.birthday);
      this.newWorkerFormData.fullName = user.fullName;
      this.newWorkerFormData.emailAddress = user.emailAddress;
      this.newWorkerFormData.gender = user.gender;
      this.newWorkerFormData.identityNumber = user.identityNumber;
      this.newWorkerFormData.streetName = user.streetName;
      this.newWorkerFormData.cityName = user.cityName;
      this.newWorkerFormData.houseNumber = user.houseNumber;
      this.newWorkerFormData.zipCode = user.zipCode;
      this.newWorkerFormData.phoneNumber = user.phoneNumber;
      this.newWorkerFormData.birthdayDay = birthday.date();
      this.newWorkerFormData.birthdayMonth = birthday.month() + 1;
      this.newWorkerFormData.birthdayYear = birthday.year();
    },
    valid() {
      if (this.valid) {
        this.$emit("input", true);
      } else {
        this.$emit("input", false);
      }
    },
  },
};
</script>
