<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="12" sm="6">
        <v-card outlined>
          <v-card-title> Kontaktinformationen </v-card-title>
          <v-card-text>
            <p>Name: {{ formBaseData.fullName }}</p>
            <p>Geburtstdatum: {{ birthdayString }}</p>
            <p>Ausweisnummer: {{ formBaseData.identityNumber }}</p>
            <p>E-Mail: {{ formBaseData.emailAddress }}</p>
            <p>
              Adresse: {{ formBaseData.streetName }} {{ formBaseData.houseNumber }}, {{ formBaseData.zipCode }}
              {{ formBaseData.cityName }}
            </p>
            <p>Telefonnummer: {{ formBaseData.phoneNumber }}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card outlined>
          <v-card-title> Buchungsinformationen </v-card-title>
          <v-card-text>
            <p>
              Teststandort: {{ formAppointmentData.org.streetName }} {{ formAppointmentData.org.houseNumber }},
              {{ formAppointmentData.org.zipCode }}
              {{ formAppointmentData.org.cityName }}
            </p>
            <p>Termin: {{ appointmentTime }}</p>
            <p v-if="formAppointmentData.testType == 1">Testtyp: PCR</p>
            <p v-if="formAppointmentData.testType == 0 || formAppointmentData.testType == 8">Testtyp: Antigen-Schnelltest</p>
            <p>Dokumentensprache: {{ formAppointmentData.language }}</p>
          </v-card-text>
        </v-card>
        <v-text-field v-if="formAppointmentData.testType == 1" v-model="voucher" class="mt-4" required label="Gutschein Voucher" outlined> </v-text-field>
        <div v-if="error != ''" class="red--text">{{ error }}</div>
      </v-col>
    </v-row>
    <div class="d-flex justify-end">
      <v-btn depressed height="2.8rem" class="white--text font-weight-bold mt-5" color="primary" style="text-transform: none" @click="booking">Buchung abschließen</v-btn>
    </div>
  </v-form>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "Completion",
  props: { formAppointmentData: Object, formBaseData: Object },
  computed: {
    appointmentTime: function(props) {
      return dayjs(props.formAppointmentData.testTime.appointmentDate).format("DD.MM.YYYY HH:mm");
    },
    birthdayString: function() {
      return this.formBaseData.birthdayDay + "." + this.formBaseData.birthdayMonth + "." + this.formBaseData.birthdayYear;
    },
  },
  data() {
    return {
      valid: false,
      voucher: "",
      error: "",
    };
  },
  methods: {
    booking() {
      var birthday = new Date(this.formBaseData.birthdayYear, this.formBaseData.birthdayMonth - 1, this.formBaseData.birthdayDay);
      this.$http({
        method: "post",
        url: "/testAppointment",
        data: {
          fullName: this.formBaseData.fullName,
          streetName: this.formBaseData.streetName,
          houseNumber: this.formBaseData.houseNumber,
          zipCode: this.formBaseData.zipCode,
          cityName: this.formBaseData.cityName,
          birthday: birthday.getTime(),
          gender: this.formBaseData.gender,
          testType: this.formAppointmentData.testType,
          language: this.formAppointmentData.language,
          org: this.formAppointmentData.org.id,
          appointment: this.formAppointmentData.testTime.id,
          emailAddress: this.formBaseData.emailAddress,
          identityNumber: this.formBaseData.identityNumber,
          phoneNumber: this.formBaseData.phoneNumber,
          voucher: this.voucher,
        },
      })
        .then((res) => {
          if ((this.formAppointmentData.testType == 1 || this.formAppointmentData.testType == 3 || this.formAppointmentData.testType == 8) && this.voucher == "") {
            let link = "";
            if (this.formAppointmentData.testType == 1) {
              link = "https://secure.affilibank.de/corona/index?product=35960&custom=" + res.data.id;
            } else if (this.formAppointmentData.testType == 3) {
              link = "https://secure.affilibank.de/corona/index?product=36156&custom=" + res.data.id;
            } else if (this.formAppointmentData.testType == 8) {
              link = "https://secure.affilibank.de/corona/index?product=35958&custom=" + res.data.id;
            }

            var params = [
              { name: "basic_addr_email", value: this.formBaseData.emailAddress },
              {
                name: "billing_addr_firstname",
                value: this.formBaseData.fullName.split(" ")[0],
              },
              {
                name: "billing_addr_lastname",
                value: this.formBaseData.fullName.split(" ")[1],
              },
              { name: "billing_addr_street_name", value: this.formBaseData.streetName },
              { name: "billing_addr_housenumber", value: this.formBaseData.houseNumber },
              { name: "billing_addr_zip", value: this.formBaseData.zipCode },
              { name: "billing_addr_city", value: this.formBaseData.cityName },
            ];

            var form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", link);
            params.forEach((param) => {
              var input = document.createElement("input");
              input.type = "hidden";
              input.name = param["name"];
              input.value = param["value"];
              form.appendChild(input);
            });
            document.body.appendChild(form);

            form.submit();
          }

          this.$emit("input", true);
        })
        .catch((err) => {
          if (err.response.status == 406) {
            this.error = "Gutscheincode nicht gültig!";
          }
          console.log("AXIOS ERROR: ", err);
        });
    },
  },
};
</script>
