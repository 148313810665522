<template>
  <v-form ref="form" v-model="valid">
    <div v-if="!this.user.fullName">Wenn Sie schon einen Account haben, können Sie sich hier <a @click="$router.push('/login')">anmelden</a> oder sich neu <a @click="$router.push('/registration')">registrieren</a>.</div>
    <div class="d-flex flex-column flex-md-row align-md-center justify-space-between">
      <div class="text-md-h6">Testort wählen</div>
      <v-select
        class="pt-6"
        v-model="testpointSelect"
        :items="testpoints"
        :rules="requiredRuleTestpoint"
        :item-text="textPlace"
        :item-value="textPlace"
        label="Testort wählen"
        outlined
        required
        return-object
        style="max-width: 600px"
      >
      </v-select>
    </div>

    <div v-if="testpointSelect.streetName" class="d-flex flex-column flex-md-row align-md-center justify-space-between mt-md-6">
      <div class="text-md-h6">Datum wählen</div>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="date" label="Datum wählen" readonly class="mt-5 mt-md-0" outlined :rules="requiredRule" v-bind="attrs" v-on="on" style="max-width: 600px"></v-text-field>
        </template>
        <v-date-picker v-model="date" scrollable :allowed-dates="allowedDates" locale="de" :first-day-of-week="1" min="2021-01-01" max="2024-01-01" @input="menu = false"> </v-date-picker>
      </v-menu>
    </div>

    <div v-if="date" class="d-flex flex-column flex-md-row align-md-center justify-space-between">
      <div class="text-md-h6">Termin wählen</div>
      <v-select class="pt-6" v-model="appointmentSelect" :items="appointments()" item-value="id" :rules="requiredRuleAppointment" label="Termin wählen" outlined return-object style="max-width: 600px">
        <template v-slot:item="{ item }"> {{ timeStampToDate(item.appointmentDate) }} ({{ getFreeSlots(item) }} freie Plätze) </template>
        <template v-slot:selection="{ item }">
          {{ timeStampToDate(item.appointmentDate) }}
        </template>
      </v-select>
    </div>

    <div v-if="appointmentSelect.id" class="text-md-h6 mt-md-6">Testtyp wählen</div>
    <v-radio-group v-if="appointmentSelect.id" v-model="testType" :rules="requiredRule">
      <v-row>
        <v-col cols="4">
          <v-card v-if="appointmentSelect.antigen" outlined>
            <v-card-title>Covid-19-Antigen Schnelltest</v-card-title>
            <v-card-subtitle>Gratis-Schnelltest</v-card-subtitle>
            <v-card-text>
              Vereinbaren Sie hier Ihren Termin für den kostenlosen Covid-19-Antigen Schnelltest. Der Abstrich wird in wenigen Minuten durch unser medizinisches Personal genommen. Das Ergebnis erhalten Sie nach ca. 30
              Minuten per E-Mail.
            </v-card-text>
            <v-card-actions>
              <v-radio label="Auswählen" value="0"></v-radio>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card v-if="appointmentSelect.pcr" outlined>
            <v-card-title>Covid-19-PCR-Test</v-card-title>
            <v-card-subtitle>84,90 €</v-card-subtitle>
            <v-card-text>
              Liefert einen zuverlässigen Nachweis auf eine akute Corona-Infektion und wird i.d.R. von Behörden, z.B. bei Reisen ins Ausland, anerkannt. Die Auswertung dauert etwa 24 Stunden.
            </v-card-text>
            <v-card-actions>
              <v-radio label="Auswählen" value="1"></v-radio>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card v-if="appointmentSelect.igm" outlined>
            <v-card-title>IgM - Antikörpertest aus venösem Blut mit Laborbefund</v-card-title>
            <v-card-subtitle>49,50 €</v-card-subtitle>
            <v-card-text>
              IgM-Antikörper werden schon während der infektiösen Phase einer COVID-19 Erkrankung gebildet. Ein negatives IgM-Testergebnis ist beispielsweise bei der Einreise nach China erforderlich..
            </v-card-text>
            <v-card-actions>
              <v-radio label="Auswählen" value="3"></v-radio>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card v-if="appointmentSelect.igg" outlined>
            <v-card-title>Antikörpertest nach Infektion</v-card-title>
            <v-card-subtitle>39 €</v-card-subtitle>
            <v-card-text>
              Antikörpertest nach überstandener Coronavirus-Infektion Bei diesem Test wird das Blut auf das Vorhandensein bestimmter Antikörper getestet. Diese spezifischen Antikörper können nur nach einer überstandenen
              Infektion mit dem Coronavirus nachgewiesen werden. Es lässt sich bei diesem Verfahren ebenfalls feststellen, wie viele dieser Antikörper sich im Blut befinden. Wie erfolgreich eine Impfung wirkt, kann durch
              diesen Test nicht bestimmt werden.
            </v-card-text>
            <v-card-actions>
              <v-radio label="Auswählen" value="4"></v-radio>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card v-if="appointmentSelect.iggs" outlined>
            <v-card-title>Antikörpertest nach Inpfung</v-card-title>
            <v-card-subtitle>39 €</v-card-subtitle>
            <v-card-text>
              Antikörpertest nach erfolgter Impfung Dieser Test gibt Aufschluss darüber, ob eine COVID-19 Impfung effektiv wirkt. Es werden dabei Antikörper im Blut nachgewiesen, die nach einer erfolgten Impfung
              entstehen. Darüber hinaus kann die Anzahl dieser Antikörper gemessen werden. Je mehr von diesen vorhanden sind, desto effektiver wirkt der Impfschutz.
            </v-card-text>
            <v-card-actions>
              <v-radio label="Auswählen" value="5"></v-radio>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card v-if="appointmentSelect.paidantigen" outlined>
            <v-card-title>Covid-19-Antigen Schnelltest</v-card-title>
            <v-card-subtitle>18,50 €</v-card-subtitle>
            <v-card-text
              >Vereinbaren Sie hier Ihren Termin für den Covid-19-Antigen Schnelltest. Der Abstrich wird in wenigen Minuten durch unser medizinisches Personal genommen. Das Ergebnis erhalten Sie nach ca. 30 Minuten per
              E-Mail.</v-card-text
            >
            <v-card-actions>
              <v-radio label="Auswählen" value="8"></v-radio>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-radio-group>

    <div v-if="testType != ''" class="d-flex flex-column flex-md-row align-md-center justify-space-between">
      <div class="text-md-h6">Sprache(Befund) wählen</div>
      <v-select class="pt-6" v-model="languageSelect" :items="languages" item-text="text" item-value="value" :rules="requiredRule" required label="Sprache für Befund wählen" outlined style="max-width: 600px"></v-select>
    </div>
  </v-form>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "Entry",
  data() {
    return {
      requiredRule: [(v) => !!v || "Bitte füllen Sie das Feld aus."],
      requiredRuleTestpoint: [(v) => !!v.id || "Bitte füllen Sie das Feld aus."],
      requiredRuleAppointment: [(v) => !!v.id || "Bitte füllen Sie das Feld aus."],
      languages: [
        { text: "Deutsch", value: "de" },
        { text: "Englisch", value: "en" },
      ],
      languageSelect: "",
      testType: "",
      testpoints: Object,
      testpointSelect: {},
      appointmentSelect: {},
      date: null,
      menu: false,
      valid: false,
    };
  },
  updated() {
    this.$emit("update:formAppointmentData", this.newAppointment);
  },
  mounted() {
    this.$http({
      method: "get",
      url: "/testpoint",
    })
      .then((response) => {
        this.testpoints = response.data;
        this.loading = false;
      })
      .catch((err) => {
        this.errored = true;
        this.loading = false;
        console.log("AXIOS ERROR: ", err);
      });
  },
  computed: {
    newAppointment: function() {
      return {
        org: this.testpointSelect,
        language: this.languageSelect,
        testType: this.testType,
        testDate: this.date,
        testTime: this.appointmentSelect,
      };
    },
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    valid() {
      if (this.valid) {
        this.$emit("input", true);
      } else {
        this.$emit("input", false);
      }
    },
  },
  methods: {
    allowedDates(val) {
      let allowedDates = [];

      for (let index in this.testpointSelect.appointments) {
        allowedDates.push(dayjs(this.testpointSelect.appointments[index].appointmentDate).format("YYYY-MM-DD"));
      }

      allowedDates = [...new Set(allowedDates)];

      if (allowedDates.indexOf(val) !== -1) {
        return true;
      } else {
        return false;
      }
    },

    appointments() {
      let appointments = this.testpointSelect.appointments.filter((app) => app.appointmentDate >= dayjs(this.date).valueOf() && app.appointmentDate <= dayjs(this.date).valueOf() + 86400000);

      appointments = appointments.map((item) => {
        if (item.bookedTests >= item.amountOfPeople) {
          item.disabled = true;
        }

        return item;
      });

      return appointments;
    },

    timeStampToDate(timeStamp) {
      // convert from ms to sec
      return dayjs(timeStamp).format("HH:mm");
    },

    getFreeSlots(item) {
      return item.amountOfPeople - item.bookedTests;
    },
    textPlace: (item) => item.name + " - " + item.streetName + " " + item.houseNumber + ", " + item.zipCode + " " + item.cityName,
  },
};
</script>
