<template>
  <v-container v-if="!showSuccess" style="width: 100%" class="mt-md-16 mt-8">
    <div class="d-flex text-md-h4 text-h5 text-center justify-center mb-md-16 mb-10">
      Buchen Sie Ihren Corona-Test-Termin in drei einfachen Schritten
    </div>
    <div class="d-flex flex-md-row flex-column justify-space-around align-center mb-10">
      <div class="d-flex flex-column align-center">
        <v-img max-width="40" contain src="../assets/testtyp.png"></v-img>
        <div class="mt-2">
          1. Test auswählen
        </div>
      </div>
      <div class="d-flex flex-column align-center mt-6 mt-md-0">
        <v-img max-width="60" contain src="../assets/person.png"></v-img>
        <div class="mt-2">2. Kontaktdaten ausfüllen</div>
      </div>
      <div class="d-flex flex-column align-center mt-6 mt-md-0">
        <v-img max-width="60" contain src="../assets/calendar.png"></v-img>
        <div class="mt-2">3. Buchung überprüfen</div>
      </div>
    </div>
    <v-stepper v-model="page" style="box-shadow: none" non-linear vertical>
      <!---------------------------------------------------------->
      <!---------------- Page 1 ---------------------------------->
      <!---------------------------------------------------------->
      <v-stepper-step :complete="page > 1" step="1">
        Test auswählen
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card outlined class="pa-5">
          <v-card-title>
            <div class="text-md-h4 primary--text font-weight-bold">
              Test auswählen
            </div>
          </v-card-title>

          <entry :formAppointmentData.sync="formAppointmentData" :user="user" v-model="isAppointmentDataValid"> </entry>
        </v-card>
        <div class="d-flex flex-row justify-start mt-5 mb-8">
          <v-btn
            depressed
            height="2.8rem"
            class="white--text font-weight-bold"
            color="primary"
            style="text-transform: none"
            @click="nextPage"
            :disabled="!isAppointmentDataValid"
            >Nächster Schritt</v-btn
          >
        </div>
      </v-stepper-content>

      <!---------------------------------------------------------->
      <!---------------- Page 2 ---------------------------------->
      <!---------------------------------------------------------->
      <v-stepper-step :complete="page > 2" step="2">
        Kontaktdaten ausfüllen
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-card outlined class="pa-5">
          <v-card-title>
            <div class="text-md-h4 primary--text font-weight-bold">
              Kontaktdaten ausfüllen
            </div>
          </v-card-title>

          <base-data :user="currentUser" :formBaseData.sync="formBaseData" v-model="isFormBaseDataValid"> </base-data>
        </v-card>
        <div class="d-flex flex-md-row flex-column justify-start mt-5 mb-8">
          <v-btn
            depressed
            height="2.8rem"
            class="white--text font-weight-bold"
            color="primary"
            style="text-transform: none"
            @click="previousPage"
            >Vorheriger Schritt</v-btn
          >
          <v-btn
            depressed
            height="2.8rem"
            class="white--text font-weight-bold ml-md-5 ml-0 mt-3 mt-md-0"
            color="primary"
            style="text-transform: none"
            @click="nextPage"
            :disabled="!isFormBaseDataValid"
            >Nächster Schritt</v-btn
          >
        </div>
      </v-stepper-content>

      <!---------------------------------------------------------->
      <!---------------- Page 3 ---------------------------------->
      <!---------------------------------------------------------->

      <v-stepper-step step="3">Buchung überprüfen</v-stepper-step>

      <v-stepper-content step="3">
        <v-card outlined class="pa-5">
          <v-card-title>
            <div class="text-md-h4 primary--text font-weight-bold">
              Buchung überprüfen
            </div>
          </v-card-title>
          <completion :formBaseData="formBaseData" :formAppointmentData="formAppointmentData" v-model="showSuccess">
          </completion>
        </v-card>
        <div class="d-flex flex-row justify-start mt-5 mb-8">
          <v-btn
            depressed
            height="2.8rem"
            class="white--text font-weight-bold"
            color="primary"
            outlined
            style="text-transform: none"
            @click="previousPage"
            >Buchung korrigieren</v-btn
          >
        </div>
      </v-stepper-content>
    </v-stepper>
  </v-container>
  <v-container v-else style="width: 100%" class="mt-md-16 mt-8">
    <div class="d-flex text-md-h4 text-h5 text-center justify-center mb-md-16 mb-10">
      <div class="d-flex flex-column align-center">
        <v-img max-width="70" contain src="../assets/done-800px.png"></v-img>
        <div class="pt-8">
          Vielen Dank für Ihre Buchung.<br />
          Sie erhalten in kürze eine Email um Ihre Buchung zu bestätigen.
          <br />
          Dies kann einige Minuten in Anspruch nehmen.
          <p>
            <v-btn color="primary" @click="reload()">
              Weiteren Test buchen
            </v-btn>
          </p>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import Entry from "../components/test_booking/Entry.vue";
import BaseData from "../components/test_booking/BaseData.vue";
import Completion from "../components/test_booking/Completion.vue";

export default {
  name: "TestBooking",
  components: {
    Entry,
    BaseData,
    Completion,
  },
  data() {
    return {
      page: 1,
      showSuccess: false,
      isFormBaseDataValid: false,
      isAppointmentDataValid: false,
      formBaseData: {},
      formAppointmentData: {},
      currentUser: {},
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    reload() {
      window.location.reload();
    },

    nextPage() {
      this.currentUser = this.$store.state.user;
      console.log(this.currentUser);
      this.page++;
      this.$vuetify.goTo(0, this.goToOptions);
    },
    previousPage() {
      this.page--;
      this.$vuetify.goTo(0, this.goToOptions);
    },
  },
};
</script>
